import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const privacyPage  = () => (
    <Layout siteTitle={"WiN's Financials"}>

    {/* top banner */}
    <div className="relative bg-indigo-800">
      <div className="absolute inset-0">
        <StaticImage
          className="h-full w-full object-cover"
          src="../images/FinancialsBanner.jpg"
          alt=""
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Our Privacy Policy</h1>
        <p className="mt-6 font-light max-w-3xl text-xl text-indigo-100">
        Your Privacy Matters: Our Commitment to Protecting Your Information
        </p>
      </div>
    </div>

    {/* main body */}
    <div className="bg-white">
      <div className="py-24 px-6 sm:px-6 sm:py-32 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-xl font-bold tracking-tight text-gray-900">
            Privacy Policy
          </h2>
          <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600">
            At Women in Neuroscience (WiN), we are committed to protecting your privacy and the security of your personal information.
          </p>
          <div className="mt-10 text-left">
            <h3 className="text-lg font-semibold text-gray-900 mb-2">
              Information We Collect
            </h3>
            <p className="text-gray-600 leading-7">
              When you subscribe to our newsletter, we collect your email address. We use this information solely for sending you newsletters and updates about WiN.
            </p>
            
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
              Use of Your Information
            </h3>
            <p className="text-gray-600 leading-7">
              We do not share, sell, or distribute your email address to third parties without your consent. We use your email address only for the purpose of sending you relevant updates.
            </p>
            
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
              Opting Out
            </h3>
            <p className="text-gray-600 leading-7">
              You can opt out of our newsletter at any time by clicking the "Unsubscribe" link at the bottom of our emails.
            </p>
            
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
              Data Security
            </h3>
            <p className="text-gray-600 leading-7">
              We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, or destruction.
            </p>
            
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
              Cookies and Analytics
            </h3>
            <p className="text-gray-600 leading-7">
              Our website may use cookies for statistical analysis and optimization. These cookies do not contain personal information.
            </p>
            
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
              Changes to this Policy
            </h3>
            <p className="text-gray-600 leading-7">
              We may update this privacy policy to reflect changes in our practices. Updates will be posted on our website.
            </p>
            
            <h3 className="text-lg font-semibold text-gray-900 mt-6 mb-2">
              Contact Us
            </h3>
            <p className="text-gray-600 leading-7">
              If you have questions or concerns about this privacy policy, please contact us <a className="text-decoration-line: underline hover:font-bold" href="/about-us/contact">here</a>.
            </p>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export const Head = () => <Seo title="WiN's Privacy Policy" />

export default privacyPage